import OnScreen from 'onscreen';
import imagesLoaded from 'imagesloaded';
import PhotoSwipeLightbox from './photoswipe-lightbox.esm.js'

// import function to register Swiper custom elements
import { register } from 'swiper/element/bundle';
// register Swiper custom elements
register();



// if (!Modernizr.touch) {
// console.log('Please enable touch');
var os = new OnScreen({
    tolerance: 0,
    debounce: 100,
    container: window,
    toggleClass: 'imHere'
});

os.on('enter', '.scrollimation', (element, event) => {
    element.classList.add('onScreen');
    // console.log(event);
    // jQuery(this).addClass('onScreen');
});
// }




document.addEventListener('DOMContentLoaded', function () {


    const initSwiper = document.querySelector('.swiper-referenzen');


    // Set the parameters for the swiper
    const swiperParams = {
        slidesPerView: 1.5,
        spaceBetween: 16,
        navigation: true,
        breakpoints: {
            640: {
                slidesPerView: 2,
                spaceBetween: 16,
            },
            768: {
                slidesPerView: 3,
                spaceBetween: 32,
            },
            1024: {
                slidesPerView: 4,
                spaceBetween: 32,
            },
        },
        injectStyles: [
            `
            .swiper-button-next,
            .swiper-button-prev {
                color: var(--color-turquoise);
                top: 22px;
                width: 10px;
            }
            .swiper-button-next {
                right: 16px;
            }
            .swiper-button-prev {
                left: 16px;
            }
            .swiper-wrapper {
                padding-top: 3em;
                padding-left: 1em;
            }

            @media screen and (min-width: 768px) {
                .swiper-wrapper {
                    padding-left: 32px;
                }
                .swiper-button-next {
                    right: 32px;
                }
                .swiper-button-prev {
                    left: 32px;
                }
            }
            @media screen and (min-width: 1536px) {
                .swiper-wrapper {
                    padding-left: 0;
                }
                .swiper-button-next {
                    right: 0px;
                }
                .swiper-button-prev {
                    left: 0px;
                }
            }



      `,
        ],
    };

    if (initSwiper) {


        // Assign the parameters to the swiper element
        Object.assign(initSwiper, swiperParams);

        // Initialize the swiper
        initSwiper.initialize();


    }


    /**
     * Toggles the menu by adding or removing necessary classes from elements.
     */
    function toggleMenu() {
        let main_navigation = document.querySelector('.menu'); // Selects the main navigation element with class 'menu'
        let html = document.querySelector('html'); // Selects the HTML element
        var body = document.querySelector('body');

        body.classList.toggle('overflow-body-hidden');
        main_navigation.classList.toggle('is-open'); // Toggles the 'is-open' class on the main navigation element
        html.classList.toggle('overflow-y-hidden'); // Toggles the 'overflow-y-hidden' class on the HTML element
        html.classList.toggle('menu-open'); // Toggles the 'menu-open' class on the HTML element
    }



    document.querySelector('.nav-toggle').addEventListener('click', function (e) {

        e.preventDefault();
        toggleMenu();

    });


    window.addEventListener('scroll', function () {
        var html = document.querySelector('html');
        var scrollPosition = window.scrollY || document.documentElement.scrollTop;

        if (scrollPosition >= 155) {
            html.classList.add('sticky');
            html.classList.remove('top');
        } else {
            html.classList.add('top');
            html.classList.remove('sticky');
        }
    });



    // Using querySelector instead of jQuery
    const toggleLightboxList = document.querySelectorAll('[data-toggle="kkg-lightbox"]');

    console.log(toggleLightboxList);

    // Adding event listener
    toggleLightboxList.forEach(el => {
        el.addEventListener('click', function (event) {

            event.preventDefault();
            const backEasing = {
                in: 'cubic-bezier(0.6, -0.28, 0.7, 1)',
                out: 'cubic-bezier(0.3, 0, 0.32, 1.275)',
                inOut: 'cubic-bezier(0.68, -0.55, 0.265, 1.55)'
            };


            const leftArrowSVGString = '<?xml version="1.0" encoding="UTF-8"?><svg id = "Ebene_2" data-name="Ebene 2" xmlns = "http://www.w3.org/2000/svg" viewBox = "0 0 31.83 12.11" ><g id="Ebene_1-2" data-name="Ebene 1"><polygon points="31.83 5.05 3.55 5.05 6.79 1.31 5.28 0 0 6.1 5.29 12.11 6.79 10.79 3.5 7.05 31.83 7.05 31.83 5.05" fill="#010202" /></g></svg> ';
            const rightArrowSVGString = '<?xml version="1.0" encoding="utf-8"?><svg version="1.1" id="Ebene_2_00000129887467899262526530000010237705509005989784_"xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 31.8 12.1"style="enable-background:new 0 0 31.8 12.1;" xml:space="preserve"><style type="text/css">.st0{fill:#010202;}</style><g id="Ebene_1-2"><polygon class="st0" points="0,7.1 28.3,7.1 25,10.8 26.5,12.1 31.8,6 26.5,0 25,1.3 28.3,5.1 0,5.1 	" /></g></svg>';

            const lightbox = new PhotoSwipeLightbox({
                gallery: '.block-gallery',
                children: 'a:not(a.button)',
                showHideAnimationType: 'zoom',
                mainClass: 'pswp--custom-bg',
                showHideAnimationType: 'zoom',
                showAnimationDuration: 700,
                hideAnimationDuration: 700,
                // arrowPrevSVG: leftArrowSVGString,
                // arrowNextSVG: rightArrowSVGString,
                counter: false,
                zoom: false,
                bgOpacity: 1,
                imageClickAction: 'next',
                tapAction: 'next',
                pswpModule: () => import('./photoswipe.esm.js'),

                paddingFn: (viewportSize) => {
                    return {
                        top: 16, bottom: 16, left: 16, right: 16
                    }
                }


            });
            lightbox.on('uiRegister', function () {
                lightbox.pswp.ui.registerElement({
                    name: 'custom-caption',
                    order: 20,
                    isButton: false,
                    appendTo: '.pswp__img',
                    html: 'Caption text',
                    onInit: (el, pswp) => {
                        lightbox.pswp.on('change', () => {
                            const currSlideElement = lightbox.pswp.currSlide.data.element;
                            let captionHTML = '';
                            if (currSlideElement) {
                                const hiddenCaption = currSlideElement.querySelector('.hidden-caption-content');
                                if (hiddenCaption) {
                                    // get caption from element with class hidden-caption-content
                                    captionHTML = hiddenCaption.innerHTML;
                                } else {
                                    // get caption from alt attribute
                                    captionHTML = currSlideElement.querySelector('img').getAttribute('alt');
                                }
                            }
                            el.innerHTML = captionHTML || '';
                        });
                    }
                });
            });

            lightbox.on('firstUpdate', () => {
                lightbox.pswp.options.easing = backEasing.out;
            });
            lightbox.on('initialZoomInEnd', () => {
                lightbox.pswp.options.easing = backEasing.inOut;
            });
            lightbox.on('close', () => {
                lightbox.pswp.options.easing = backEasing.in;
            });
            lightbox.on('beforeOpen', () => {
                console.log('beforeOpen');
                // photoswipe starts to open
            });
            lightbox.init();

            event.preventDefault();
            const textImagesGallery = new PhotoSwipeLightbox({
                gallery: '#text-images-gallery',
                children: 'a:not(a.button)',
                showHideAnimationType: 'zoom',
                mainClass: 'pswp--custom-bg',
                pswpModule: () => import('./photoswipe.esm.js')
            });
            textImagesGallery.init();
        });
    });

});